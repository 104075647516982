html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "Young Serif";
  src: url(./fonts/YoungSerif-Regular.ttf) format("truetype");
}

.abs-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-parent {
  width: 100%;
  height: 100%;
}

.cover-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.abs-cover-parent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/*
  prefer this over .abs-cover-parent when the overlay is not necessarily
    going to cover the entire parent
*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
}

.fixed-full-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.fixed-to-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
}

.flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes opacity-pulse {
  50% {
    opacity: 0.2;
  }
}
